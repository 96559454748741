/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import './Contact.styl';

import MainLayout from '../views/layouts/MainLayout';
import Parallax from '../views/components/Parallax';
import SEO from '../views/components/SEO';
import ContactForm from '../views/components/ContactForm';

const ContactPage = ({
    pageContext: { locale, originalPath },
    location,
    data,
}) => {
    const { apiUrl } = data.site.siteMetadata;
    return (
        <MainLayout
            locale={locale}
            originalPath={originalPath}
            location={location}
        >
            <SEO
                title='pages.contact.headerTitle'
                description='pages.contact.metaDescription'
                originalPath={originalPath}
                location={location}
                metaIcon={data.seoImage.childImageSharp.resize.src}
            />
            <div className='page-bg'>
                <Parallax
                    img={data.coverImage.childImageSharp.fluid}
                    alt='image-contact-groupe-editor'
                    overlay='light'
                >
                    <div className='page-intro contact'>
                        <div className='container'>
                            <div className='left'>
                                <h1>
                                    <FormattedMessage id='pages.contact.title' />
                                </h1>
                            </div>
                            <ContactForm apiUrl={apiUrl} />
                        </div>
                    </div>
                </Parallax>
            </div>
            <div className='page-content' />
        </MainLayout>
    );
};

ContactPage.propTypes = {
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        originalPath: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default ContactPage;

export const query = graphql`
    query {
        site {
            siteMetadata {
                apiUrl
            }
        }
        coverImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "contact.jpg" }
        ) {
            ...BgImages
        }
        seoImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "contact.jpg" }
        ) {
            ...SeoImages
        }
    }
`;
