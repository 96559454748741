import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { injectIntl, FormattedMessage } from 'react-intl';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import EmailIcon from '@material-ui/icons/Email';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import siteConfig from '../../../../config/siteConfig';

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '12px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

const ContactForm = ({ classes, intl: { formatMessage }, apiUrl }) => {
    const [fields, setFields] = useState({
        object: '',
        email: '',
        message: '',
        mailSent: false,
        error: false,
    });
    const [captchaValue, setCaptchaValue] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFields({ ...fields, mailSent: false, error: false });
    };

    const handleChange = name => event => {
        setFields({ ...fields, [name]: event.target.value });
    };

    const onCaptchaChange = value => {
        setCaptchaValue(value);
    };

    const handleSubmit = () => {
        console.log('TCL: handleSubmit -> captchaValue', captchaValue);
        if (captchaValue) {
            axios({
                method: 'post',
                url: apiUrl,
                headers: {
                    'content-type': 'application/json',
                },
                data: fields,
            })
                .then(result => {
                    setFields({ ...fields, mailSent: true, error: false });
                })
                .catch(() => {
                    setFields({ ...fields, mailSent: true, error: true });
                });
        } else {
            console.log('need validate captcha');
        }
    };

    return (
        <Container maxWidth='xs'>
            <CssBaseline />
            <div
                className={classes.paper}
                style={{
                    backgroundColor: 'white',
                    borderRadius: '6px',
                }}
            >
                <Avatar className={classes.avatar}>
                    <EmailIcon />
                </Avatar>
                <Typography component='h2' color='primary'>
                    <FormattedMessage id='pages.contact.form.intro' />
                </Typography>
                <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextValidator
                                variant='outlined'
                                fullWidth
                                select
                                label={formatMessage({
                                    id: 'pages.contact.form.object.label',
                                })}
                                id='outlined-select-object'
                                name='object'
                                onChange={handleChange('object')}
                                value={fields.object}
                                margin='dense'
                                validators={['required']}
                                errorMessages={[
                                    formatMessage(
                                        {
                                            id: 'errors.emptyField',
                                        },
                                        { field: 'object' },
                                    ),
                                ]}
                            >
                                <MenuItem value=''>
                                    <FormattedMessage id='pages.contact.form.object.value0' />
                                </MenuItem>
                                <MenuItem value='product'>
                                    <FormattedMessage id='pages.contact.form.object.value1' />
                                </MenuItem>
                                <MenuItem value='comm'>
                                    <FormattedMessage id='pages.contact.form.object.value2' />
                                </MenuItem>
                                <MenuItem value='crea'>
                                    <FormattedMessage id='pages.contact.form.object.value3' />
                                </MenuItem>
                            </TextValidator>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                variant='outlined'
                                fullWidth
                                label={formatMessage({
                                    id: 'pages.contact.form.email.label',
                                })}
                                id='email'
                                name='email'
                                onChange={handleChange('email')}
                                value={fields.email}
                                autoComplete='email'
                                margin='dense'
                                validators={['required', 'isEmail']}
                                errorMessages={[
                                    formatMessage(
                                        {
                                            id: 'errors.emptyField',
                                        },
                                        { field: 'email' },
                                    ),
                                    formatMessage({
                                        id: 'errors.invalidEmail',
                                    }),
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                variant='outlined'
                                fullWidth
                                id='outlined-textarea'
                                label='Message'
                                name='message'
                                placeholder={formatMessage({
                                    id: 'pages.contact.form.message.label',
                                })}
                                onChange={handleChange('message')}
                                FormHelperTextProps={{ variant: 'outlined' }}
                                value={fields.message}
                                multiline
                                margin='dense'
                                validators={['required']}
                                errorMessages={[
                                    formatMessage(
                                        {
                                            id: 'errors.emptyField',
                                        },
                                        { field: 'message' },
                                    ),
                                ]}
                            />
                        </Grid>
                    </Grid>
                    <div
                        className='captcha-bloc'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '20px 0 0',
                        }}
                    >
                        <ReCAPTCHA
                            sitekey={siteConfig.reCaptchaKey}
                            onChange={onCaptchaChange}
                            tabindex={3}
                        />
                    </div>
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        className={classes.submit}
                        color='primary'
                    >
                        <FormattedMessage id='pages.contact.form.submit' />
                    </Button>
                </ValidatorForm>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={fields.mailSent}
                autoHideDuration={6000}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={
                    <FormattedMessage
                        tagName='span'
                        id='pages.contact.form.sent'
                    />
                }
                action={[
                    <IconButton
                        key='close'
                        aria-label='Close'
                        color='inherit'
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        </Container>
    );
};

ContactForm.propTypes = {
    classes: PropTypes.object.isRequired,
    apiUrl: PropTypes.string.isRequired,
};

export default injectIntl(withStyles(styles)(ContactForm));
